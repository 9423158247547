<template>
  <v-container fluid class="py-0">
    <v-row class="px-5">
      <v-col cols="12">
        <v-row class="pt-3" v-if="$store.getters['auth/isStudio']">
          <v-col
            cols="12"
            md="4"
            :style="
              !$vuetify.breakpoint.xsOnly
                ? 'display:flex; align-items:center'
                : 'display:flex; justify-content: center; margin-bottom: 10px;'
            "
            class="py-0 px-0"
          >
            {{ $t("artists") }}
          </v-col>

          <v-col cols="6" md="4" class="pl-0 py-0">
            <Import :valor="'artist'" :type="'tattooer'"></Import>
          </v-col>
          <v-col cols="6" md="4" class="py-0 pr-0">
            <v-btn
              class="ma-1"
              elevation="0"
              outlined
              :style="'width: 100%; height: 30px'"
              @click="
                getExcel('tattooer');
                this.type = 'tattooer';
              "
              ><v-icon small left>$download</v-icon>
              <span style="color: white; text-transform: normal !important">{{
                $t("export")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col
            cols="12"
            :style="
              !$vuetify.breakpoint.xsOnly
                ? 'display:flex; align-items:center'
                : 'display:flex; justify-content: center; margin-bottom: 10px;'
            "
            md="4"
            class="py-0 px-0"
          >
            {{ $tc("customer", 2) }}
          </v-col>

          <v-col cols="6" md="4" class="py-0 pl-0">
            <Import :valor="'customer'" :type="'customer'"></Import>
          </v-col>
          <v-col cols="6" md="4" class="py-0 pr-0">
            <v-btn
              class="ma-1"
              elevation="0"
              outlined
              :style="'width: 100%; height: 30px'"
              @click="
                getExcel('customer');
                this.type = 'customer';
              "
              ><v-icon small left>$download</v-icon>
              <span style="color: white; text-transform: normal !important">{{
                $t("export")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col
            cols="12"
            md="4"
            class="py-0 px-0"
            :style="
              !$vuetify.breakpoint.xsOnly
                ? 'display:flex; align-items:center'
                : 'display:flex; justify-content: center; margin-bottom: 10px;'
            "
          >
            {{ $t("economy") }}
          </v-col>

          <v-col cols="6" md="4" class="py-0 pl-0">
            <Import :valor="'expense'" :type="'expense'"></Import>
          </v-col>
          <v-col cols="6" md="4" class="py-0 pr-0">
            <v-btn
              class="ma-1"
              elevation="0"
              outlined
              :style="'width: 100%; height: 30px'"
              @click="getExcel('expense')"
              ><v-icon small left>$download</v-icon>
              <span style="color: white; text-transform: normal !important">{{
                $t("export")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col
            cols="12"
            md="4"
            class="py-0 px-0"
            :style="
              !$vuetify.breakpoint.xsOnly
                ? 'display:flex; align-items:center'
                : 'display:flex; justify-content: center; margin-bottom: 10px;'
            "
          >
            {{ $t("inventory") }}
          </v-col>

          <v-col cols="6" md="4" class="py-0 pl-0">
            <Import :valor="'inventory'" :type="'inventory'"></Import>
          </v-col>
          <v-col cols="6" md="4" class="py-0 pr-0">
            <v-btn
              class="ma-1"
              elevation="0"
              outlined
              :style="'width: 100%; height: 30px'"
              @click="getExcel('inventory')"
              ><v-icon small left>$download</v-icon>
              <span style="color: white; text-transform: normal !important">{{
                $t("export")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col
            cols="12"
            md="4"
            class="py-0 px-0"
            :style="
              !$vuetify.breakpoint.xsOnly
                ? 'display:flex; align-items:center'
                : 'display:flex; justify-content: center; margin-bottom: 10px;'
            "
          >
            {{ $t("inventorys.movement") }}
          </v-col>

          <v-col cols="6" md="4" class="py-0 pl-0">
            <Import :valor="'movement'" :type="'movement'"></Import>
          </v-col>
          <v-col cols="6" md="4" class="py-0 pr-0">
            <v-btn
              class="ma-1"
              elevation="0"
              outlined
              :style="'width: 100%; height: 30px'"
              @click="getExcel('movement')"
              ><v-icon small left>$download</v-icon>
              <span style="color: white; text-transform: normal !important">{{
                $t("export")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import excelGenerator from "@/mixins/excelGenerator";
export default {
  name: "ExportacionImportacionSettings",
  mixins: [excelGenerator],

  components: {
    Import: () => import("@/components/exp-imp/Import"),
  },
  data() {
    return {
      tab: "expimp",
      value: "exp-imp",
      key: {
        artist: null,
        customer: null,
        expense: null,
      },
      headersMovement: [
        {
          text: this.$t("inventorys.invoice_number"),
          align: "center",
          value: "invoice_number",
        },
        {
          text: this.$t("Fecha"),
          align: "center",
          value: "data",
        },
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          value: "inventory.product_cody",
        },
        {
          text: this.$t("inventorys.description"),
          align: "center",
          value: "inventory.description",
        },

        {
          text: this.$t("Cantidad"),
          align: "center",
          value: "cantidad",
        },
        {
          text: this.$t("tattooers.price"),
          align: "center",
          value: "total",
        },
      ],
      headersCustomer: [
        {
          text: this.$t("first_name"),
          align: "center",
          value: "user.first_name",
        },
        {
          text: this.$t("last_name"),
          align: "center",
          value: "user.last_name",
        },
        {
          text: this.$t("tattooer.birthdate"),
          align: "center",
          value: "birthdate",
        },
        {
          text: this.$t("email"),
          align: "center",
          value: "user.email",
        },
        {
          text: this.$t("phone"),
          align: "center",
          value: "user.phone",
        },
        {
          text: this.$t("customers.city"),
          align: "center",
          value: "city",
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
        },
      ],
      headersTattooer: [
        {
          text: this.$t("first_name"),
          value: "user.first_name",
          align: "center",
        },
        {
          text: this.$t("last_name"),
          value: "user.last_name",
          align: "center",
        },
        {
          text: this.$t("artist"),
          value: "type_artist",
          align: "center",
        },
        {
          text: this.$t("email"),
          value: "user.email",
          align: "center",
        },
        {
          text: this.$t("phone"),
          value: "user.phone",
          align: "center",
        },

        {
          text: this.$t("tattooers.token_payment"),
          value: "token",
          align: "center",
        },
        {
          text: this.$t("tattooers.state"),
          value: "state",
          align: "center",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
        },
      ],
      headersExpense: [
        {
          text: this.$tc("expenses.concepto", 2),
          align: "center",
          value: "conceptos",
        },
        {
          text: this.$t("expenses.data"),
          align: "center",
          value: "data",
        },
        {
          text: this.$t("expenses.cantidad"),
          align: "center",
          value: "cantidad",
        },
        /*{
              text: this.$t("expenses.pending"),
              value: "pending",
            },*/
        {
          text: this.$tc("tattooer"),
          align: "center",
          value: "tattooer",
        },
        {
          text: this.$t("expenses.recurrente"),
          align: "center",
          value: "recurrente",
        },

        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    headersInventory() {
      let array = [
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          value: "product_cody",
        },
        {
          text: this.$t("Descripción"),
          align: "center",
          value: "description",
        },
        {
          text: this.$t("brand"),
          align: "center",
          value: "brand.brand_name",
        },
        {
          text: this.$t("category"),
          align: "center",
          value: "category.category_name",
        },
        {
          text: this.$t("inventorys.supplier"),
          align: "center",
          value: "supplier.supplier_name",
        },
        {
          text: this.$t("product.price"),
          align: "center",
          value: "product_price",
        },

        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
        },
      ];
      return array;
    },
    headersBox() {
      let array = [
        {
          text: this.$t("boxes.name"),
          align: "center",
          value: "name",
        },
      ];
      //payment active
      if (this.$store.getters["auth/getSetting"]("payment_box") == 1) {
        array.push({
          text: this.$t("boxes.price"),
          align: "center",
          value: "price",
        });
        array.push({
          text: this.$t("boxes.token_payment"),
          align: "center",
          value: "token_payment",
        });
      }

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        value: "status",
      });
      //TATTOOER ASSOCIATE

      if (
        this.$store.getters["auth/getSetting"]("box_tattooer_associate") == 1
      ) {
        array.push({
          text: this.$t("boxes.tattooer_associate"),
          align: "center",
          value: "user.first_name",
        });
      }

      array.push({
        text: this.$t("actions"),
        align: "center",
        value: "actions",
      });

      return array;
    },
  },
  methods: {
    ...mapActions("expenses", ["getAll"]),
    ...mapActions("customers", ["getAllCustomers"]),
    ...mapActions("studios", ["getAllTattooers"]),
    ...mapActions("boxes", ["getTable", "generatePdf"]),
    ...mapActions("inventory", ["getAllInventorys"]),
    ...mapActions("inventorymovements", ["getAllTotal"]),
    ...mapActions("users", ["settingUpdate", "getSetting"]),

    getExcel(value) {
      if (value === "tattooer") {
        this.getAllTattooers({
          filters: {
            from: this.from,
            to: this.to,
            tattooer_id: this.tattooer_id,
            ...this.filters,
          },
        }).then((data) => {
          //console.log(data);
          this.exportToExcel(data, this.headersTattooer, this.$t("artists"));
        });
      } else if (value === "customer") {
        this.getAllCustomers({
          filters: {
            from: this.from,
            to: this.to,
            tattooer_id: this.tattooer_id,
            ...this.filters,
          },
        }).then((data) => {
          //console.log(data);
          this.exportToExcel(data, this.headersCustomer, this.$t("customer"));
        });
      } else if (value === "box") {
        this.generatePdf().then((value) => {
          //console.log(value);
        });
      } else if (value === "expense") {
        this.getAll({
          filters: {
            from: new Date().toISOString().substr(0, 10),
            to: new Date().toISOString().substr(0, 10),
          },
        }).then((data) => {
          //console.log(data.box);
          this.exportToExcel(data, this.headersExpense, this.$t("economy"));
        });
      } else if (value === "inventory") {
        this.getAllInventorys({
          filters: {},
        }).then((data) => {
          //console.log(data);

          this.exportToExcel(
            data.data,
            this.headersInventory,
            this.$t("inventory")
          );
        });
      } else if (value === "movement") {
        this.getAllTotal().then((data) => {
          //console.log(data);

          this.exportToExcel(
            data.data,
            this.headersMovement,
            this.$t("inventorys.movement")
          );
        });
      }
    },
  },
};
</script>

<style lang="sass">
#imp
label
  display: block
  height: 25px
</style>