var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$store.getters['auth/isStudio'])?_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0 px-0",style:(!_vm.$vuetify.breakpoint.xsOnly
              ? 'display:flex; align-items:center'
              : 'display:flex; justify-content: center; margin-bottom: 10px;'),attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.$t("artists"))+" ")]),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"6","md":"4"}},[_c('Import',{attrs:{"valor":'artist',"type":'tattooer'}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-1",style:('width: 100%; height: 30px'),attrs:{"elevation":"0","outlined":""},on:{"click":function($event){_vm.getExcel('tattooer');
              this.type = 'tattooer';}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$download")]),_c('span',{staticStyle:{"color":"white","text-transform":"normal !important"}},[_vm._v(_vm._s(_vm.$t("export")))])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0 px-0",style:(!_vm.$vuetify.breakpoint.xsOnly
              ? 'display:flex; align-items:center'
              : 'display:flex; justify-content: center; margin-bottom: 10px;'),attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.$tc("customer", 2))+" ")]),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"6","md":"4"}},[_c('Import',{attrs:{"valor":'customer',"type":'customer'}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-1",style:('width: 100%; height: 30px'),attrs:{"elevation":"0","outlined":""},on:{"click":function($event){_vm.getExcel('customer');
              this.type = 'customer';}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$download")]),_c('span',{staticStyle:{"color":"white","text-transform":"normal !important"}},[_vm._v(_vm._s(_vm.$t("export")))])],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0 px-0",style:(!_vm.$vuetify.breakpoint.xsOnly
              ? 'display:flex; align-items:center'
              : 'display:flex; justify-content: center; margin-bottom: 10px;'),attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.$t("economy"))+" ")]),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"6","md":"4"}},[_c('Import',{attrs:{"valor":'expense',"type":'expense'}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-1",style:('width: 100%; height: 30px'),attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.getExcel('expense')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$download")]),_c('span',{staticStyle:{"color":"white","text-transform":"normal !important"}},[_vm._v(_vm._s(_vm.$t("export")))])],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0 px-0",style:(!_vm.$vuetify.breakpoint.xsOnly
              ? 'display:flex; align-items:center'
              : 'display:flex; justify-content: center; margin-bottom: 10px;'),attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.$t("inventory"))+" ")]),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"6","md":"4"}},[_c('Import',{attrs:{"valor":'inventory',"type":'inventory'}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-1",style:('width: 100%; height: 30px'),attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.getExcel('inventory')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$download")]),_c('span',{staticStyle:{"color":"white","text-transform":"normal !important"}},[_vm._v(_vm._s(_vm.$t("export")))])],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0 px-0",style:(!_vm.$vuetify.breakpoint.xsOnly
              ? 'display:flex; align-items:center'
              : 'display:flex; justify-content: center; margin-bottom: 10px;'),attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.$t("inventorys.movement"))+" ")]),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"6","md":"4"}},[_c('Import',{attrs:{"valor":'movement',"type":'movement'}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-1",style:('width: 100%; height: 30px'),attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.getExcel('movement')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$download")]),_c('span',{staticStyle:{"color":"white","text-transform":"normal !important"}},[_vm._v(_vm._s(_vm.$t("export")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }